import { KeyboardArrowDown } from "@mui/icons-material"
import {
  SelectChangeEvent,
  Grid,
  Typography,
  Autocomplete,
  Checkbox,
  Chip,
  Select,
  TextField,
  Box,
  MenuItem,
} from "@mui/material"
import { FormikErrors } from "formik"

import { CreativeTagTypes, TagObjectType } from "../model/creative"

export type TagViewComponentProps = {
  tagData: TagObjectType[]
  value: TagObjectType[]
  values: Record<
    string,
    {
      tags: TagObjectType[]
      valueTags: TagObjectType[]
      finalTags: CreativeTagTypes
      category_id: string
    }
  >
  creativeId: string
  errors: FormikErrors<
    Record<
      string,
      {
        tags: TagObjectType[]
        valueTags: TagObjectType[]
        finalTags: CreativeTagTypes
        category_id: string
      }
    >
  >
  handleSelectChange: (e: SelectChangeEvent<"">, tag: TagObjectType) => void
  handleTextFieldChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    tag: TagObjectType,
  ) => void
  handleChange: (
    e: React.SyntheticEvent<Element, Event>,
    value: (string | TagObjectType)[],
  ) => void
  isGroupTag: boolean
}

export const TagViewComponent = ({
  tagData,
  value,
  values,
  creativeId,
  errors,
  handleSelectChange,
  handleTextFieldChange,
  handleChange,
  isGroupTag,
}: TagViewComponentProps) => {
  return !!values[creativeId].category_id.length ? (
    <Grid
      container
      item
      direction="column"
      gap="26px"
      bgcolor={isGroupTag ? "tranparent" : "#FFF"}
      p="12px"
      borderRadius="4px"
    >
      {!!tagData?.length ? (
        <TagsSelectorComponent
          tagData={tagData}
          value={value}
          handleChange={handleChange}
          disablePortal={false}
        />
      ) : null}
      {!!values[creativeId].tags.filter((d) => !d.compulsory).length ? (
        <Grid container gap={2}>
          {values[creativeId].tags
            .filter((d) => !d.compulsory)
            .map((tag: TagObjectType, index: number) => (
              <TagFieldComponent
                key={tag.tagName}
                creativeId={creativeId}
                tag={tag}
                errors={errors}
                values={values}
                handleSelectChange={handleSelectChange}
                handleTextFieldChange={handleTextFieldChange}
              />
            ))}
        </Grid>
      ) : null}
      {!!values[creativeId].tags.filter((d) => d.compulsory).length ? (
        <Grid
          container
          gap={2}
          direction="column"
          flexWrap="nowrap"
          sx={{
            background: "#fff",
            border: "0.1px solid #EB4335",
            borderRadius: "4px",
            p: "12px",
          }}
        >
          <Typography
            fontSize="12px"
            sx={{
              minWidth: 116,
              pt: "4px",
              color: "#EB4335",
            }}
          >
            Compulsory Tags
          </Typography>
          <Grid container gap={2}>
            {values[creativeId].tags
              .filter((d) => d.compulsory)
              .map((tag: TagObjectType, index: number) => (
                <TagFieldComponent
                  key={tag.tagName}
                  creativeId={creativeId}
                  tag={tag}
                  errors={errors}
                  values={values}
                  handleSelectChange={handleSelectChange}
                  handleTextFieldChange={handleTextFieldChange}
                />
              ))}
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  ) : null
}

export type TagsSelectorComponentProps = {
  tagData: TagObjectType[] | undefined
  value: TagObjectType[]
  handleChange: (
    e: React.SyntheticEvent<Element, Event>,
    value: (string | TagObjectType)[],
  ) => void
  fullWidth?: boolean
  disablePortal?: boolean
}

const TagSelectorStyles = () => ({
  autocomplete: {
    "& .MuiInputBase-root": {
      minHeight: "34px",
      padding: "0px 8px",
      backgroundColor: "#FFF",
      borderRadius: "20px",
      border: "0.2px solid #AAA",
    },
    "& .MuiAutocomplete-tag": {
      display: "none",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiOutlinedInput-root .MuiAutocomplete-input": {
      paddingLeft: "16px",
    },
    "& .MuiAutocomplete-popper": {
      border: "4px solid #000",
    },
  },
})

export const TagsSelectorComponent = ({
  tagData,
  value,
  handleChange,
  fullWidth,
  disablePortal = true,
}: TagsSelectorComponentProps) => {
  const customStyles = TagSelectorStyles()
  return !!tagData?.length && !!value ? (
    <Autocomplete
      multiple
      fullWidth={fullWidth}
      disablePortal={disablePortal}
      disableClearable
      isOptionEqualToValue={(option, value) => {
        if (option.tagName === value.tagName) return true
        else return false
      }}
      sx={{
        width: "300px",
        height: "40px",
        borderRadius: "6px",
        border: "1px solid #ddd",
        bgcolor: "#fff",
        "& fieldset": {
          border: "none",
        },
        "& .MuiInputBase-root": {
          padding: "0 8px",
        },
      }}
      options={tagData}
      value={value}
      getOptionDisabled={(option) => option.compulsory === true}
      getOptionLabel={(option) => {
        if (typeof option === "object") {
          return option.tagName
        } else {
          return option
        }
      }}
      onChange={handleChange}
      freeSolo
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Search or Select Tag Type"
          InputProps={{
            ...params.InputProps,
            // @ts-ignore
            disableunderline: "true",
            type: "search",
          }}
        />
      )}
      renderOption={(props, option, { selected, index }) => {
        return (
          <li {...props} key={`${option.tagName}`}>
            <Grid
              key={`${option.tagName}-${index}`}
              container
              alignItems="center"
            >
              <Checkbox
                style={{
                  marginRight: 8,
                }}
                checked={selected}
              />
              <Grid
                item
                container
                direction="column"
                width="auto"
                gap="18px"
                justifyContent="flex-end"
              >
                <Grid item container>
                  <Typography variant="caption" fontWeight={500}>
                    {option.tagName}
                  </Typography>
                  {option.compulsory ? (
                    <Typography
                      sx={{
                        color: "#EB4335",
                        fontSize: "8px",
                      }}
                    >
                      *
                    </Typography>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </li>
        )
      }}
      renderTags={() => <></>}
      forcePopupIcon
      popupIcon={<KeyboardArrowDown />}
    />
  ) : null
}

export type TagFieldComponentProps = {
  tag: TagObjectType
  handleSelectChange: (e: any, tag: TagObjectType) => void
  handleTextFieldChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    tag: TagObjectType,
  ) => void
  creativeId: string
  values: Record<
    string,
    {
      tags: TagObjectType[]
      valueTags: TagObjectType[]
      finalTags: CreativeTagTypes
    }
  >
  errors: FormikErrors<
    Record<
      string,
      {
        tags: TagObjectType[]
        valueTags: TagObjectType[]
        finalTags: CreativeTagTypes
      }
    >
  >
}

const TagViewStyles = () => ({
  select: {
    minWidth: 130,
    height: 25,
    backgroundColor: "#FFF",
    fontSize: "10px",
    lineHeight: "15px",
    "& .MuiOutlinedInput-input": {
      padding: "0px 16px",
    },
    "& .MuiList-root": {
      backgroundColor: "#DDD",
    },
    "& .MuiButtonBase-root.Mui-selected": {
      backgroundColor: "#DDD",
      "&:hover": {
        backgroundColor: "#FFF",
      },
    },
    "& .MuiSvgIcon-root": {
      color: "#000",
      fontSize: "16px",
    },
  },
  chip: {
    justifyContent: "space-between",
    height: "25px",
    minWidth: "130px",
    fontSize: "10px",
    lineHeight: "15px",
    borderRadius: "4px",
    background: "#C9BDF5",
    "&.MuiChip-clickable:hover": {
      backgroundColor: "#C9BDF5",
    },
    "& .MuiChip-deleteIcon": {
      width: "12px",
      height: "12px",
      color: "#000",
    },
  },
  textField: {
    "& .MuiInputBase-input": {
      height: "25px",
      paddingInline: "unset",
      paddingBlock: "unset",
      padding: "0px 0px 0px 8px",
      fontSize: "12px",
      backgroundColor: "#FFF",
    },
  },
})

export const TagFieldComponent = ({
  tag,
  handleSelectChange,
  handleTextFieldChange,
  creativeId,
  errors,
  values,
}: TagFieldComponentProps) => {
  let tagValue = values[creativeId].finalTags[tag.tagName]
  if (!tagValue || tagValue === undefined || tagValue === "") tagValue = []

  const validationError = errors[`${creativeId}.finalTags.${tag.tagName}`]
  return (
    <Grid
      container
      flexWrap="nowrap"
      gap="13px"
      width="auto"
      key={`${tag.tagName}`}
    >
      {tag.tagStatus === "closed" ? (
        <Grid
          container
          display="flex"
          direction="column"
          gap={1}
          sx={
            !!validationError
              ? {
                  color: "red",
                  "& .MuiInputBase-root": {
                    borderColor: "red",
                  },
                }
              : {}
          }
        >
          <Typography fontSize="14px" fontWeight="400">
            {tag.tagName}
          </Typography>
          <Select
            sx={{
              minWidth: "200px",
              height: "40px",
              borderRadius: "6px",
              border: "1px solid #ddd",
              "& fieldset": {
                border: "none",
              },
              bgcolor: "#fff",
            }}
            placeholder={`Select ${tag.tagName}`}
            multiple={tag.isMultiple}
            value={typeof tagValue === "string" ? [tagValue] : tagValue}
            onChange={(e) => handleSelectChange(e, tag)}
            renderValue={(selected) => {
              if (tag.isMultiple) {
                const MAX_DISPLAYED = 2 // Maximum number of chips to show
                const selectedItemsToShow = selected.slice(0, MAX_DISPLAYED) // Show first two items
                const remainingCount = selected.length - MAX_DISPLAYED // Remaining items count

                return (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selectedItemsToShow.map((value) => (
                      <Chip
                        sx={{
                          display: "inline-flex",
                          height: "28px",
                          minWidth: "90px",
                          padding: "4px 10px",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "5px",
                          borderRadius: "4px",
                          background: "#E9E9E9",
                        }}
                        key={value}
                        label={value}
                      />
                    ))}
                    {remainingCount > 0 && (
                      <Chip
                        sx={{
                          display: "inline-flex",
                          height: "28px",
                          minWidth: "20px",
                          padding: "4px 10px",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "5px",
                          borderRadius: "4px",
                          background: "#E9E9E9",
                        }}
                        label={`+${remainingCount}...`}
                      />
                    )}
                  </Box>
                )
              } else {
                return selected[0] // Single selection, show the value directly
              }
            }}
          >
            {tag.enum?.map((name) => (
              <MenuItem key={name} value={name}>
                {tag.isMultiple ? (
                  <Checkbox
                    checked={tagValue.indexOf(name) > -1}
                    sx={{
                      padding: "0 10px 0 0",
                      "& svg": {
                        fill: "#ddd",
                      },
                      "&.Mui-checked svg": {
                        fill: "#000",
                      },
                    }}
                  />
                ) : null}
                <Typography fontSize="14px" fontWeight="400">
                  {name}
                </Typography>
              </MenuItem>
            ))}
          </Select>
          {!!validationError ? (
            <Typography fontSize="10px" color="red">
              {validationError as string}
            </Typography>
          ) : null}
        </Grid>
      ) : null}
      {tag.tagStatus === "open" ? (
        <Grid
          display="flex"
          direction="column"
          gap={1}
          sx={
            !!validationError
              ? {
                  color: "red",
                  "& .MuiTextField-root": {
                    borderColor: "red",
                  },
                }
              : {}
          }
        >
          <Typography fontSize="14px" fontWeight="400">
            {tag.tagName}
          </Typography>
          <Grid container direction="column" gap="4px" width="auto">
            <TextField
              name={`${creativeId}.finalTags.${tag.tagName}`}
              value={tagValue}
              placeholder="Type..."
              onChange={(e) => handleTextFieldChange(e, tag)}
              type={tag.type === "string" ? "text" : "number"}
              sx={{
                minWidth: "200px",
                height: "40px",
                borderRadius: "6px",
                border: "1px solid #ddd",
                padding: "0 16px",
                bgcolor: "#fff",
                "& input": {
                  padding: 0,
                  border: "none",
                  width: "100%",
                  height: "100%",
                },
                "& fieldset": {
                  border: "none",
                },
                "& .MuiInputBase-root": {
                  height: "100%",
                },
              }}
              inputProps={
                tag.type === "number"
                  ? { min: 0, maxLength: 50 }
                  : { maxLength: 50 }
              }
            />
          </Grid>
          {!!validationError ? (
            <Typography fontSize="10px" color="red">
              {validationError as string}
            </Typography>
          ) : null}
        </Grid>
      ) : null}
    </Grid>
  )
}
