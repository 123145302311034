import JSZip from "jszip"
import { saveAs } from "file-saver"

export type fileDetails = {
  filename: string
  url: string
}

export function downloadToUser(data: Blob, defaultFileName: string) {
  const link = document.createElement("a")
  link.style.display = "none"
  document.body.appendChild(link)
  link.href = window.URL.createObjectURL(new Blob([data]))
  link.setAttribute("download", defaultFileName)
  link.click()
  window.URL.revokeObjectURL(link.href)
  document.body.removeChild(link)
}

export function downloadS3AsBlob(url: string, fileName: string) {
  return fetch(url, {
    method: "GET",
    headers: { "Cache-Control": "no-cache" },
  })
    .then((response) => response.blob())
    .then((blob) => {
      downloadToUser(blob, fileName)
    })
    .catch((err) => console.log(err))
}

export const downloadFilesAsZip = async (data: fileDetails[]) => {
  const zip = new JSZip()

  const filePromises = data.map(async (file) => {
    const response = await fetch(file.url)
    const blob = await response.blob()
    zip.file(file.filename, blob)
  })

  await Promise.all(filePromises)

  const zipBlob = await zip.generateAsync({ type: "blob" })
  saveAs(zipBlob, "creatives.zip")
}

const getInitials = (str: string) => {
  const initialsArr = str.split(" ")
  let initialsStr = ""
  initialsArr.forEach((s) => {
    initialsStr += s[0].toUpperCase()
  })
  return initialsStr
}

export const createCustomName = (
  id: string,
  tagData: Record<string, string | string[]>,
  s3Filename: string,
  assetCategory: Record<string, string> | undefined,
) => {
  const fileExtension = s3Filename.replace(id, "")

  const getTagValue = (key: string, joinWith = "-") => {
    const value = tagData[key]
    if (Array.isArray(value)) {
      return value.join(joinWith)
    }
    return value || "-"
  }

  if (!!Object.keys(tagData).length) {
    let nameStr = ""
    switch (assetCategory?.display_name) {
      case "Game - Video":
        nameStr += getTagValue("Game")
        nameStr += `-${getTagValue("Serial Num")}`
        nameStr += `-${getTagValue("Concept").replaceAll(" ", "-")}`
        nameStr += `-${getTagValue("Project Type")}`
        nameStr += `-${getTagValue("Project level")}`
        nameStr += `-${getTagValue("Duration")}`
        nameStr += `-${getTagValue("Dimension")}-`
        break

      case "Game - Image":
        nameStr += getTagValue("Game")
        nameStr += `-${getTagValue("Project Type - image")}`
        nameStr += `-${getTagValue("Artist")}`
        nameStr += `-${getTagValue("Devices")}`
        nameStr += `-${getTagValue("Platform")}`
        nameStr += `-${getTagValue("Dimension")}`
        nameStr += `-${getTagValue("Serial Num")}-`
        break

      case "Other - Video":
        nameStr += getTagValue("Project Name")
        nameStr += `-${getTagValue("Project Type")}`
        nameStr += `-${getTagValue("Artist")}`
        nameStr += `-${getTagValue("Version Number")}-`
        break

      case "Other - Image":
        nameStr += `-${getTagValue("Project Name")}`
        nameStr += `-${getTagValue("Project Type - image")}`
        nameStr += `-${getTagValue("Artist")}`
        nameStr += `-${getTagValue("Version Number")}-`
        break

      default:
        nameStr += getTagValue("Game")
        nameStr += `-${getTagValue("Serial Num")}`
        nameStr += `-${getTagValue("Concept").replaceAll(" ", "-")}`
        nameStr += `-${getTagValue("Project Type - image")}`
        nameStr += `-${getTagValue("Project level")}`
        nameStr += `-${getTagValue("Duration")}`
        nameStr += `-${getTagValue("Dimension")}-`
        break
    }

    return `${nameStr}${id}${fileExtension}`
  } else {
    return s3Filename
  }
}
